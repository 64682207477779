import axios from "axios";
import store from "@/store";
import useLogout from "./useLogout";
import { useToast } from "vue-toastification";

export default function useRequest(options) {
  if (!options || typeof options !== "object") return;
  const apiUrl = store.state.api;
  const toast = useToast();

  const config = Object.assign(
    {
      method: "get",
      timeout: 20000
    },
    options.config
  );

  if (options.path) {
    config.url = apiUrl + options.path;
  } else if (!config.url) {
    return;
  }
  const status = options.status || 200;

  const defaultError = "Something went wrong!";

  const showError = (msg) => {
    if (msg) toast.error(msg || defaultError);
  };

  const showSuccess = (msg) => {
    if (msg) toast.success(msg);
  };
  return axios(config)
    .then((response) => {
      if (response && response.status === status) {
        if (options.msg) {
          showSuccess(options.msg);
        }
        return response.data || true;
      } else {
        showError();
        return false;
      }
    })
    .catch((error) => {
      if (!error.response) {
        showError();
        return;
      }

      if (!error.response.data) {
        showError();
        return;
      }

      const requestError =
        error.response.data.errors ||
        error.response.data.error ||
        error.response.data.message ||
        error.response.data;

      if (!requestError) {
        showError();
      } else if (typeof requestError === "string") {
        showError(requestError);
      } else if (Array.isArray(requestError)) {
        showError(requestError[0]);
      } else if (typeof requestError === "object") {
        const errorKeys = Object.keys(requestError);
        let firstError = errorKeys[0] && requestError[errorKeys[0]];
        if (Array.isArray(firstError)) firstError = firstError[0];
        showError(firstError);
      } else {
        showError();
      }

      if (error.response.status === 401) {
        if (store.state.access_token) showError("You are not unauthenticated.");
        useLogout();
        return;
      }
    });
}
