import { createStore } from "vuex";

export default createStore({
  state: {
    access_token: "",
    api: process.env.VUE_APP_API,
    regions: [],
    funding_type: [
      { id: "equity", label: "Equity" },
      { id: "debt", label: "Debt" },
      { id: "hybrid", label: "Hybrid" },
      { id: "other", label: "Other" }
    ],
    deal_size: [
      {
        id: "0_500000",
        label: "0-500k"
      },
      {
        id: "500000_1000000",
        label: "500k-1m"
      },
      {
        id: "1000000_3000000",
        label: "1m-3m"
      },
      {
        id: "3000000_5000000",
        label: "3m-5m"
      },
      {
        id: "5000000_10000000",
        label: "5m-10m"
      },
      {
        id: "10000000_20000000",
        label: "10m-20m"
      },
      {
        id: "20000000_50000000",
        label: "20m-50m"
      },
      {
        id: "50000000_100000000",
        label: "50m-100m"
      },
      {
        id: "100000000",
        label: "100m +"
      }
    ]
  },
  getters: {},
  mutations: {
    setRegions(state, payload) {
      state.regions = payload;
    },
    setToken(state, payload) {
      state.access_token = payload || "";
    }
  },
  actions: {},
  modules: {}
});
