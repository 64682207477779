import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import useRequest from "@/composables/useRequest";
import useLogout from "@/composables/useLogout";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  __name: 'App',
  setup(__props) {
    /* eslint-disable */
    const router = useRouter();
    const store = useStore();
    const $cookies = inject("$cookies");
    const accessToken = computed(() => {
      return store.state.access_token;
    });
    store.commit("setToken", $cookies.get("access_token"));
    router.beforeEach((to, from, next) => {
      if (!accessToken.value && !to.meta.unauthorized) {
        useLogout();
        return;
      }
      let nextPage = undefined;
      if (accessToken.value && to.meta.unauthorized) {
        nextPage = "/deals";
      } else if (!accessToken.value && !to.meta.unauthorized) {
        nextPage = "/";
      }
      next(nextPage);
    });
    watch(() => accessToken.value, val => {
      const token = val ? "Bearer " + val : null;
      axios.defaults.headers.common["Authorization"] = token;
      if (val) {
        $cookies.set("access_token", val, "Infinity");
      } else {
        $cookies.remove("access_token");
      }
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
                                                          
      return _openBlock(), _createBlock(_component_v_app, null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      });
    };
  }
};

/* Vuetify */
import { VApp as _component_v_app } from "vuetify/lib/components/VApp/index.mjs"

