import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import { loadFonts } from "./plugins/webfontloader";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

const toast_options = {
  position: "top-right",
  duration: 5000,
  useDefaultCss: false
};

loadFonts();

createApp(App)
  .use(router)
  .use(VueCookies)
  .use(store)
  .use(vuetify)
  .use(Toast, toast_options)
  .mount("#app");
